.text-font-style {
  font-family: Georgia, "Times New Roman", Times, serif;
  resize: none;
  overflow: auto;
  overflow-wrap: break-word;
  height: 30px;
}

.text-font-style:focus {
  box-shadow: 0;
  outline: none !important;
}

.writing-title {
  font-size: xx-large;
  font-weight: bold;
}

.writing-desc {
  font-size: large;
  line-height: 25px;
  color: #4a4a4a !important;
}

.text-faded-in {
  transition: opacity 1s ease;
}

.text-faded-out {
  opacity: 0;
  transition: opacity 1s ease;
}

.text-button {
  margin: 5px;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s all ease;
  border-radius: 10px;
}

.text-button:hover {
  background-color: #d5d9ff;
  transition: 0.3s all ease;
  border-radius: 10px;
}
