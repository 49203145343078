.article-brief {
  width: 100%;
  height: 100px !important;
  resize: none;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: "#eff5ff";
  transition: ease all 0.2s;
}

.article-brief:focus {
  border: 1px solid #012042 !important;
  box-shadow: 0;
  outline: none !important;
  transition: ease all 0.2s;
}

.slider-style {
  color: #012042;
  height: 2px;
  padding: 15px 0;
}

.slider-style > .MuiSlider-thumb {
  height: 28px !important;
  width: 28px !important;
  background-color: #fff;
}

.slider-style > .MuiSlider-mark {
  opacity: 0 !important;
}
